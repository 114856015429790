<template>
  <div class="qhse">
    <head-nav></head-nav>
    <div class="qhse-banner">
      <img
        v-for="(item, index) in qhseBanner"
        :key="index"
        :src="item.imgs"
        alt=""
      >
    </div>

    <div class="qhse-wrap">
      <div class="qhse-list">
        <h3 class="qhse-list-title">
          <img
            :src="$t('qhse.title-1')"
            :style="this.$i18n.locale==='en'?'width:160px;height:auto;':''"
            alt=""
          >
        </h3>

        <div class="qhse-list-imgs">
          <div
            class="qhse-list-img"
            v-for="(item, index) in $t('qhse.list-1')"
            :key="index"
          >
            <img
              :src="item.img"
              alt=""
            >
          </div>
        </div>
      </div>

      <div class="qhse-list">
        <h3 class="qhse-list-title">
          <img
            :src="$t('qhse.titleimg')"
            alt=""
            :style="this.$i18n.locale==='en'?'width:220px;height:auto;':''"
          >
        </h3>

        <div class="qhse-list-contetn">
          <div
            class="list"
            v-for="(item, index) in $t('qhse.target')"
            :key="index"
          >
            <div class="list-top">
              {{ (index + 1).toString().padStart(2, '0') }}
            </div>
            <div class="list-bottom">
              <div class="info">
                <p>{{item.title}}</p>
                <h3>{{item.content}}</h3>
              </div>
              <img
                class="img"
                :src="item.img"
                alt=""
              >
            </div>
          </div>
        </div>
      </div>

      <div class="qhse-list">
        <h3 class="qhse-list-title">
          <img
            :src="$t('qhse.title-2')"
            alt=""
            :style="this.$i18n.locale==='en'?'width:220px;height:auto;':''"
          >
        </h3>

        <div
          class="qhse-list-imgs"
          :class=" $i18n.locale ==='en'?'qhse-list-img-active4':''"
        >
          <div
            class="qhse-list-img"
            v-for="(item, index) in $t('qhse.qhse2')"
            :key="index"
            :class="item.id === 3
              && $i18n.locale ==='en'?'qhse-list-img-active':''"
          >
            <p
              class="qhse-list-img-info"
              v-for="line in item.title"
              :key="line"
            >{{ line }}</p>
          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import gql from 'graphql-tag';

import banner from '@/assets/image/qhse/banner.png';
import HeadNav from './head-nav';
import Footers from './footers';

export default {
  components: {
    HeadNav,
    Footers,
  },

  apollo: {
    qhseImgs2: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              qhseImgs2: allCommitments {
                title
              }
            }
          `;
        }
        return gql`
          query {
            qhseImgs2: allEnCommitments {
              title
            }
          }
        `;
      },
    },
  },

  data() {
    return {
      swiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        spaceBetween: 70,
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        },
        pagination: {
          el: '.swiper-pagination',
        },
      },
      qhseBanner: [
        {
          id: 1,
          imgs: banner,
        },
      ],
      qhseImgs2: [],
    };
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.qhse {
  &-banner {
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
  .qhse-wrap {
    padding: 40px 30px 100px 30px;
    background: #f8f8f8;

    .qhse-list {
      .qhse-list-contetn {
        margin-bottom: 40px;
        .list {
          height: 320px;
          border-radius: 10px;
          overflow: hidden;

          .list-top {
            height: 70px;
            line-height: 70px;
            font-size: 40px;
            background: #ffbf00;
            padding-left: 30px;
          }

          .list-bottom {
            padding: 0 40px;
            display: flex;
            justify-content: space-between;
            .info {
              margin-top: 40px;
              p {
                margin: 10px 0;
                font-size: 42px;
              }
              h3 {
                margin: 20px 0;
                font-size: 50px;
              }
            }

            .img {
              width: 180px;
              margin-top: 40px;
            }
          }
        }
      }
      &:nth-child(3) {
        .qhse-list-imgs {
          .qhse-list-img {
            width: 344.5px;
            height: 300px;
            background: #eeeeee;
            color: #333333;

            &:nth-child(1) {
              width: 100%;
              height: 300px;
              font-size: 30px;
              background: #ffbf00;

              .qhse-list-img-info {
                margin: 100px 180px;
                font-size: 36px;
                line-height: 50px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
              }
            }

            &:nth-child(3) {
              background: #666666;
              color: #fafafa;

              .qhse-list-img-info {
                margin-top: 10px;
              }

              .qhse-list-img-info:nth-child(1) {
                margin-top: 20px;
              }
            }
            &:nth-child(4) {
              background: #666666;
              color: #fafafa;
              .qhse-list-img-info {
                margin-top: 10px;
              }

              .qhse-list-img-info:nth-child(1) {
                margin-top: 70px;
              }
            }
            &:nth-child(7) {
              background: #666666;
              color: #fafafa;
            }

            &:last-child {
              width: 344.5px;
            }

            &-info {
              margin: 50px 30px 0 30px;
              font-size: 28px;
              line-height: 34px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 6;
              overflow: hidden;
            }

            img {
              width: 100%;
              height: 300px;
            }
          }

          .qhse-list-img-active {
            margin: 0;

            .qhse-list-img-info:nth-child(1) {
              margin-top: 20px !important;
            }

            .qhse-list-img-info {
              margin: 0 10px !important;
              font-size: 1rem;
              line-height: 26px;
            }
          }
        }

        .qhse-list-img-active4 {
           .qhse-list-img:nth-child(1) .qhse-list-img-info {
            -webkit-line-clamp: 3;
            margin-top: 80px;
          }
          .qhse-list-img:nth-child(4) .qhse-list-img-info:nth-child(1) {
            margin-top: 20px;
          }
        }
      }

      &-title {
        margin: 50px 0 30px 0;
        text-align: center;

        img {
          height: 70px;
        }
      }

      &-content {
        line-height: 40px;
        font-size: 28px;
        color: #646464;
      }

      &-imgs {
        margin-top: 30px;
        width: 100%;
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        justify-content: space-around;
        border-radius: 10px;
        overflow: hidden;

        .qhse-list-img {
          width: 344.5px;

          &:nth-child(1),
          &:last-child {
            width: 100%;
          }

          img {
            width: 100%;
            height: 300px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .qhse .qhse-wrap .qhse-list-imgs .qhse-list-img,
  .qhse .qhse-wrap .qhse-list:nth-child(2) .qhse-list-imgs .qhse-list-img,
  .qhse .qhse-wrap .qhse-list:nth-child(2) .qhse-list-imgs .qhse-list-img:last-child {
    width: 288px;
  }
}
</style>
